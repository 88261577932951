<template>
  <v-container>
    <v-card class="mt-2 pa-3" color="#fafafa">
      <div class="ma-3 d-flex flex-no-wrap justify-space-between">
        <div class="ma-1">
          <v-form ref="registro">
            <div class="justify-center">
              <v-card-text class="py-2 black--text text-center">
                <v-row
                  align="end"
                  class="align-end justify-center"
                  justify="center"
                >
                  <img
                    width="100"
                    height="50"
                    src="@/assets/logo-indac-alt-filled.png"
                  />
                  <strong>{{ $store.getters.version }}</strong>
                </v-row>
              </v-card-text>
            </div>
            <br />
            <v-card-text style="width: 308px">
              <p class="font-weight-bold" style="font-size: 20px">
                Registrarse
              </p>
              <v-text-field
                prepend-inner-icon="mdi-account"
                v-model="userName"
                placeholder="RNC / RUT"
                :rules="rules.required"
                required
              ></v-text-field>
              <v-text-field
                prepend-inner-icon="mdi-key"
                v-model="password"
                placeholder="Contraseña"
                :rules="rules.required"
                required
                :append-icon="showpass ? 'mdi-eye-off' : 'mdi-eye'"
                :type="showpass ? 'text' : 'password'"
                @click:append="showpass = !showpass"
              ></v-text-field>
              <v-text-field
                prepend-inner-icon="mdi-key"
                v-model="passwordConfirmacion"
                placeholder="Confirmar contraseña"
                :rules="rules.required"
                required
                :append-icon="showConfirmpass ? 'mdi-eye-off' : 'mdi-eye'"
                :type="showConfirmpass ? 'text' : 'password'"
                @click:append="showConfirmpass = !showConfirmpass"
              ></v-text-field>
              <v-text-field
                prepend-inner-icon="mdi-barcode"
                v-model="captCha"
                placeholder="Escribe el texto que ves en la imagen"
                :rules="rules.required"
                required
              ></v-text-field>
              <v-row justify="center">
                <img :src="captChaURL" alt="" />
              </v-row>
              <v-progress-linear
                v-if="progress"
                color="red"
                indeterminate
              ></v-progress-linear>
            </v-card-text>
          </v-form>
          <v-btn
            block
            large
            depressed
            color="error"
            class="mb-2"
            @click="registrarse"
            >Registrarse <v-icon right>mdi-chevron-right</v-icon></v-btn
          >

          <a href="#/login" style="font-size: 1.15rem; color: #009dc4"
            >Volver atras</a
          >
        </div>
        <v-avatar size="65%" tile v-if="!$vuetify.breakpoint.mobile">
          <v-img :src="require('@/assets/portada.jpg')"></v-img>
        </v-avatar>
      </div>
      <v-snackbar
        :timeout="5000"
        v-model="show"
        :multi-line="true"
        :color="color"
        >{{ mensaje }}
      </v-snackbar>
    </v-card>
  </v-container>
</template>

<script>
import api from "@/api/autenticacion";

export default {
  data: () => {
    return {
      show: false,
      color: "warning",
      mensaje: "",
      mensaje1: "",
      captChaURL: "",
      captCha: "",
      captChaGenerated: "",
      userName: "",
      password: "",
      passwordConfirmacion: "",
      progress: false,
      showpass: false,
      showConfirmpass: false,
      rules: {
        required: [(v) => !!v || "Este campo es requerido"],
      },
      window: {
        height: null,
      },
    };
  },
  methods: {
    generate() {
      api.generateCaptCha().then((response) => {
        self.isExporting = false;
        if (response !== null) {
          this.captChaGenerated = response.data.codigo;
          this.captChaURL = `data:image/jpeg;base64, ${response.data.img}`;
        }
      });
    },
    reset() {
      this.show = false;
      this.$refs.registroInterno.reset();
      this.$refs.registro.reset();
    },
    registrarse() {
      this.show = false;
      this.color = "warning";
      if (this.$refs.registro.validate()) {
        if (this.captChaGenerated === this.captCha) {
          if (this.password === this.passwordConfirmacion) {
            var data = JSON.stringify({
              NombreUsuario: this.userName,
              Clave: this.password,
            });
            api
              .registrarse(data)
              .then((response) => {
                this.mensaje = response.data;
                this.color = "info";
                this.show = true;
                this.$refs.registro.reset();
                setTimeout(() => this.$router.push({ path: "/" }), 5000);
              })
              .catch((error) => {
                this.show = true;
                this.mensaje = error.response.data.Message;
                this.$refs.registro.reset();
                setTimeout(() => this.$router.push({ path: "/" }), 5000);
              });
          } else {
            this.show = true;
            this.mensaje = "Las contraseñas no coinciden";
          }
          this.generate();
        } else {
          this.show = true;
          this.captCha = "";
          this.mensaje = "El texto no coincide con el de la imagen";
          this.generate();
        }
      }
    },
    handleResize() {
      this.window.height = window.innerHeight;
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.generate();
    this.$store.dispatch("searchFormatPassword", true);
  },
  create() {
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>